@font-face {
    font-family: 'Quicksand-Bold';
    src: local('Quicksand-Bold'), url(fonts/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand-Medium';
    src: local('Quicksand-Medium'), url(fonts/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'RubikMonoOne';
    src: local('RubikMonoOne'), url(fonts/RubikMonoOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Exo';
    src: local('Exo'), url(fonts/Exo-Bold.ttf) format('truetype');
}


body {
    margin: 0px;
    font-family: Quicksand-Medium, SansSerif, serif;
    user-select: none;
    letter-spacing: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

html {
    height: 100%;
    overflow: hidden;
}